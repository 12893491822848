import Link from 'next/link';
import SubText from '@/components/typography/SubText';
import Heading3 from '@/components/typography/Heading3';
import Text from '@/components/typography/Text';
import OpenLink from '@/svgs/icons/openLink.svg';
import React from 'react';
import { useTranslation } from '@/hooks/i18n';
import { selectAvatarColor } from '@/lib/select-avatar-color';
import { Spinner } from '@toyokumo/kintoneapp-ui';
import CautionExplain from '@/components/CautionExplain';
import ProviderIcon from '@/components/ProviderIcon';

type ViewOrFormProps = {
  avatarUrl: string | null;
  providerName: string;
  viewOrFormTitle: string;
  description: string;
  uid: string;
};

function ViewOrForm({
  avatarUrl,
  showProvider,
  providerName,
  viewOrFormTitle,
  description,
  uid,
}: ViewOrFormProps & { showProvider: boolean }) {
  return (
    <div className="flex items-center space-x-4 border-b border-toyokumo-gray-200 p-4">
      {showProvider && (
        <span className="shrink-0">
          <ProviderIcon size={48} src={avatarUrl} fallbackColor={selectAvatarColor(uid)} />
        </span>
      )}
      <div className="min-w-0 grow flex-col break-words">
        {showProvider && <SubText>{providerName}</SubText>}
        <Heading3>{viewOrFormTitle}</Heading3>
        {description.length !== 0 && (
          <div className="mt-2 border-t border-dashed border-toyokumo-gray-200 pt-2">
            <Text>{description}</Text>
          </div>
        )}
      </div>
      <div className="grid place-items-center">
        <OpenLink />
      </div>
    </div>
  );
}

export type ViewOrFormLinkProps = {
  href: string;
} & ViewOrFormProps;

function ViewOrFormLink({
  avatarUrl,
  providerName: name,
  viewOrFormTitle: title,
  description,
  uid,
  href,
  showProvider,
}: ViewOrFormLinkProps & { showProvider: boolean }) {
  return (
    <Link href={href}>
      <div className="hover:bg-role-hover">
        <ViewOrForm
          avatarUrl={avatarUrl}
          showProvider={showProvider}
          providerName={name}
          viewOrFormTitle={title}
          description={description}
          uid={uid}
        />
      </div>
    </Link>
  );
}

type ViewOrFormListContentProps = {
  viewOrFormLinks: ViewOrFormLinkProps[];
  isLink?: boolean;
  showProvider?: boolean;
};

function ViewOrFormListContent({ viewOrFormLinks, isLink = true, showProvider = true }: ViewOrFormListContentProps) {
  return (
    <ul>
      {viewOrFormLinks.map(({ avatarUrl, providerName: name, viewOrFormTitle: title, description, href, uid }) => (
        <li key={href}>
          {isLink ? (
            <ViewOrFormLink
              avatarUrl={avatarUrl}
              showProvider={showProvider}
              providerName={name}
              viewOrFormTitle={title}
              description={description}
              href={href}
              uid={uid}
            />
          ) : (
            <ViewOrForm
              avatarUrl={avatarUrl}
              showProvider={showProvider}
              providerName={name}
              viewOrFormTitle={title}
              description={description}
              uid={uid}
            />
          )}
        </li>
      ))}
    </ul>
  );
}

function Loading({ showProvider = true }: { showProvider?: boolean }) {
  return (
    <div
      className={`flex items-center space-x-4 px-4 ${showProvider ? 'py-4' : 'py-7'} border-b border-toyokumo-gray-200`}
    >
      {showProvider && (
        <span className="shrink-0 text-toyokumo-gray-200">
          <ProviderIcon size={48} src={null} />
        </span>
      )}
      <div className="grid grow justify-items-center">
        <Spinner />
      </div>
      <div className="grid place-items-center opacity-30">
        <OpenLink />
      </div>
    </div>
  );
}

type ViewOrFormListProps = {
  viewOrFormLinks?: ViewOrFormLinkProps[];
  isLink?: boolean;
  showProvider?: boolean;
  isPending: boolean;
  isProvided?: boolean;
};

function ViewOrFormList({ viewOrFormLinks, isLink, showProvider, isPending, isProvided = true }: ViewOrFormListProps) {
  const { t } = useTranslation();
  if (isPending) {
    return <Loading showProvider={showProvider} />;
  }
  if (!viewOrFormLinks || viewOrFormLinks.length === 0) {
    return (
      <div className="border-b border-toyokumo-gray-200 p-4">
        {isProvided ? (
          <CautionExplain title={t('user.noView.heading') as string} explain={t('user.noView.explain') as string} />
        ) : (
          <CautionExplain
            title={t('app.profile.noAccessible.title') as string}
            explain={t('app.profile.noAccessible.explain') as string}
          />
        )}
      </div>
    );
  }
  return (
    <>
      {!isProvided && (
        <div className="border-b border-toyokumo-gray-200 p-4">
          <CautionExplain title={t('app.profile.accessible.title') as string} />
        </div>
      )}
      <ViewOrFormListContent showProvider={showProvider} isLink={isLink} viewOrFormLinks={viewOrFormLinks} />
    </>
  );
}

export default ViewOrFormList;
