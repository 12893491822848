import { IoMdInformationCircle } from 'react-icons/io';
import Heading3 from '@/components/typography/Heading3';
import Text from '@/components/typography/Text';
import React from 'react';

type CautionExplainProps = {
  title: string;
  explain?: string | null;
};

function CautionExplain({ title, explain = null }: CautionExplainProps) {
  return (
    <div className="flex-col space-y-2.5 rounded bg-toyokumo-gray-50 p-4">
      <div className="flex items-center space-x-1">
        <IoMdInformationCircle className="text-role-caution" size={24} />
        <Heading3>{title}</Heading3>
      </div>
      {explain && <Text>{explain}</Text>}
    </div>
  );
}

export default CautionExplain;
