import React, { createContext, useContext, useRef, useState } from 'react';
import { MdSearch, MdAccountCircle, MdMenu } from 'react-icons/md';
import { IoMdCloseCircle } from 'react-icons/io';
import ToyokumoKintoneAppText from '@/components/ToyokumoKintoneAppText';
import Link from 'next/link';
import HorizontalLogo from '@/components/logos/HorizontalLogo';
import ToyokumoLogo from '@/components/ToyokumoLogo';
import SearchInput from '@/components/form/SearchInput';
import type { ProviderListType } from '@/components/ProviderList';
import { ProviderList, ProviderListContext } from '@/components/ProviderList';
import { NavigationDrawerContext, NavigationDrawer } from '@/components/NavigationDrawer';
import { selectAvatarColor } from '@/lib/select-avatar-color';
import ProviderIcon from '@/components/ProviderIcon';

const SearchWindowContext = createContext<
  | {
      showSearchWindow: boolean;
      setShowSearchWindow: (v: boolean) => void;
    }
  | undefined
>(undefined);

const useSearchWindowContext = () => {
  const context = useContext(SearchWindowContext);
  if (!context) {
    throw new Error('SearchWindowContext.Provider内で使用してください');
  }
  return context;
};

type SearchWindowType = {
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
};

function SearchWindow({ value, onChange }: SearchWindowType) {
  const searchWindowContext = useSearchWindowContext();
  const height = searchWindowContext.showSearchWindow ? 'h-[72px]' : 'h-0';
  const searchInputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      {/* eslint-disable-next-line react/button-has-type */}
      <button
        className={`px-2 py-3 ${searchWindowContext.showSearchWindow ? 'bg-role-selected' : 'hover:bg-role-hover'}`}
        onClick={() => {
          if (!searchWindowContext.showSearchWindow) {
            searchWindowContext.setShowSearchWindow(true);
            searchInputRef.current?.focus();
          } else {
            searchWindowContext.setShowSearchWindow(false);
          }
        }}
      >
        <MdSearch size={24} />
      </button>
      <div
        className={`fixed inset-0 z-50 overflow-y-hidden bg-white text-black ${height} border-b border-b-toyokumo-gray-200 transition-all duration-150 ease-in-out`}
      >
        <div className="flex items-center space-x-2 py-4 pl-4 pr-2">
          <div className="grow">
            <SearchInput ref={searchInputRef} value={value} onChange={onChange} />
          </div>
          {/* eslint-disable-next-line react/button-has-type */}
          <button
            className="p-2 hover:bg-role-hover"
            onClick={() => {
              searchWindowContext.setShowSearchWindow(false);
            }}
          >
            <IoMdCloseCircle size={24} />
          </button>
        </div>
      </div>
    </>
  );
}

function ToyokumoHorizontalLogo() {
  return (
    <HorizontalLogo
      logo={<ToyokumoLogo width={32} height={32} />}
      text={<ToyokumoKintoneAppText width={165.86} height={18} />}
      space={4}
    />
  );
}

type GlobalNavProps = {
  profileAvatarUrl: string | null;
  profileName: string | null;
  profileEmail: string;
  profileUid: string;
  providerAvatarUrl?: string | null;
  providerName?: string | null;
  providerUid: string;
  isPreview?: boolean;
  showMobile?: boolean;
  searchWord?: string;
  onChangeSearch?: React.ChangeEventHandler<HTMLInputElement>;
};

export function GlobalNav({
  profileAvatarUrl,
  profileName,
  profileEmail,
  profileUid,
  providerAvatarUrl = null,
  providerName = null,
  providerUid,
  isPreview = false,
  showMobile = false,
  searchWord,
  onChangeSearch,
}: GlobalNavProps) {
  return (
    <header>
      <div className="h-12 border-b border-b-toyokumo-gray-200 bg-white">
        <nav className="flex h-full items-center justify-between pl-4 pr-2">
          {providerName ? (
            <div className="flex min-w-0 grow basis-0 items-center space-x-2">
              <span className="shrink-0">
                <ProviderIcon size={32} src={providerAvatarUrl} fallbackColor={selectAvatarColor(providerUid)} />
              </span>
              <div className="truncate text-sm font-bold">{providerName}</div>
            </div>
          ) : (
            <>
              {isPreview ? (
                <ToyokumoHorizontalLogo />
              ) : (
                <Link href="/" className="">
                  <ToyokumoHorizontalLogo />
                </Link>
              )}
            </>
          )}
          <div className="flex">
            {isPreview ? (
              <>
                <div className={`px-2 py-3 ${showMobile ? '' : 'hidden'}`}>
                  <MdSearch size={24} />
                </div>
                <div className={`px-2 py-3 ${showMobile ? '' : 'hidden'}`}>
                  <MdMenu size={24} />
                </div>
                <div className={`px-2 py-3 ${showMobile ? 'hidden' : ''}`}>
                  <MdAccountCircle size={24} />
                </div>
              </>
            ) : (
              <>
                <span className="lg:hidden">
                  <SearchWindow value={searchWord} onChange={onChangeSearch} />
                </span>
                <NavigationDrawer
                  avatarUrl={profileAvatarUrl ?? null}
                  name={profileName ?? null}
                  email={profileEmail}
                  uid={profileUid}
                />
              </>
            )}
          </div>
        </nav>
      </div>
    </header>
  );
}

type UserLayoutProps = {
  children: React.ReactNode;
  showNavigationDrawer: boolean;
  setShowNavigationDrawer: (v: boolean) => void;
} & GlobalNavProps &
  ProviderListType;

// 一般ユーザーのページのレイアウトを定義する
function UserLayout({
  children,
  providerList,
  profileAvatarUrl,
  profileName,
  profileEmail,
  profileUid,
  providerAvatarUrl = null,
  providerName = null,
  providerUid,
  searchWord,
  onChangeSearch,
  showNavigationDrawer,
  setShowNavigationDrawer,
}: UserLayoutProps) {
  const [showSearchWindow, setShowSearchWindow] = useState(false);

  return (
    <>
      {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
      <ProviderListContext.Provider value={{ providerList }}>
        {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
        <NavigationDrawerContext.Provider value={{ showNavigationDrawer, setShowNavigationDrawer }}>
          {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
          <SearchWindowContext.Provider value={{ showSearchWindow, setShowSearchWindow }}>
            <div className="fixed inset-x-0 top-0 z-30">
              <GlobalNav
                profileAvatarUrl={profileAvatarUrl}
                profileName={profileName}
                profileEmail={profileEmail}
                profileUid={profileUid}
                providerAvatarUrl={providerAvatarUrl}
                providerName={providerName}
                providerUid={providerUid}
                searchWord={searchWord}
                onChangeSearch={onChangeSearch}
              />
            </div>
          </SearchWindowContext.Provider>
        </NavigationDrawerContext.Provider>
        <div className="fixed inset-0 top-12 z-30 hidden w-[240px] overflow-y-auto px-4 lg:block">
          <ProviderList />
        </div>
      </ProviderListContext.Provider>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
      <main
        // グロナビ分paddingを入れる
        // lg以上の場合サイドメニュー分paddingを入れ、mainの最大幅は1280pxに留める
        className="max-w-screen-xl pt-12 lg:pl-[240px]"
        onClick={() => {
          setShowSearchWindow(false);
        }}
      >
        {children}
      </main>
    </>
  );
}

export default UserLayout;
