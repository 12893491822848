type Props = {
  children: React.ReactNode;
  error?: boolean;
};

function Heading3({ children, error }: Props) {
  return <h3 className={`break-words text-sm font-bold leading-5 ${error ? 'text-role-error' : ''}`}>{children}</h3>;
}

export default Heading3;
