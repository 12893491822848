import { useTranslation } from '@/hooks/i18n';
import { MdSearch } from 'react-icons/md';
import type { KeyboardEventHandler } from 'react';
import React from 'react';
import { TextInput } from '@toyokumo/kintoneapp-ui';

type Props = {
  readOnly?: boolean;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
};

// eslint-disable-next-line import/no-named-as-default-member
const SearchInput = React.forwardRef<HTMLInputElement, Props>(({ readOnly = false, value, onChange }, ref) => {
  const { t } = useTranslation();

  // スマートフォンで改行を押したときにキーボードが閉じられた方が操作性が高いので、このようにしている
  const blurOnEnter: KeyboardEventHandler = (e) => {
    if (e.key === 'Enter') {
      (e.target as HTMLElement).blur();
    }
  };

  return (
    <TextInput
      ref={ref}
      id="search"
      name="search"
      type="text"
      value={value}
      onChange={onChange}
      placeholder={t('search') as string}
      disabled={readOnly}
      onKeyDown={blurOnEnter}
      leftIcon={<MdSearch size={24} />}
    />
  );
});

SearchInput.displayName = 'SearchInput';

export default SearchInput;
